import { createModel } from '@rematch/core'
import { getVotesCount, sendVotes } from '../../api/votes/votes'

export const votes = createModel()({
  state: {
    trumpVotes: 0,
    harrisVotes: 0
  },
  reducers: {
    setTrumpVotes(state, payload) {
      return {
        ...state,
        trumpVotes: payload
      }
    },
    setHarrisVotes(state, payload) {
      return {
        ...state,
        harrisVotes: payload
      }
    }
  },
  effects: (dispatch) => ({
    async getVotes() {
      try {
        const payload = await getVotesCount()
        const { data } = payload

        dispatch({ type: 'votes/setTrumpVotes', payload: data?.REPUBLICAN })
        dispatch({ type: 'votes/setHarrisVotes', payload: data?.DEMOCRATIC })
      } catch (e) {
        console.error(e)
      }
    },
    async sendPartyVote(party) {
      try {
        const payload = await sendVotes(party)
        const { data } = payload

        if (party === 'DEMOCRATIC') {
          dispatch({ type: 'votes/setHarrisVotes', payload: data?.DEMOCRATIC })
        } else {
          dispatch({ type: 'votes/setTrumpVotes', payload: data?.REPUBLICAN })
        }
      } catch (e) {
        console.error(e)
      }
    }
  })
})
