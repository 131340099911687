import React from 'react'
import styles from './Navigation.module.scss'
import LogoIcon from '../../assets/logo.svg'

function Navigation() {
  return (
    <div className={styles.navigation}>
      <div className={styles.navigation__logo}>
        <img src={LogoIcon} alt="logo" />
      </div>
      <div className={styles.navigation__links}>
        <a
          href="https://x.com/cyber_zombi_bot"
          target="_blank"
          className={styles.navigation__links__item}
        >
          X (TWITTER)
        </a>
        <a
          href="https://t.me/ZombiBotChat"
          target="_blank"
          className={styles.navigation__links__item}
        >
          TELEGRAM
        </a>
        <a
          href="https://zombi-bot.gitbook.io/ciber-zombi-bot"
          target="_blank"
          className={styles.navigation__links__item}
        >
          GITBOOK
        </a>
        <a
          href="https://discord.com/invite/XfF5h5Y7N9"
          target="_blank"
          className={styles.navigation__links__item}
        >
          DISCORD
        </a>
        <button className={styles.navigation__links__button}>
          <a href="https://t.me/ZombiBotBeta_bot" target="_blank">
            PLAY
          </a>
        </button>
      </div>
    </div>
  )
}

export default Navigation
