import React from 'react'
import styles from './Fund.module.scss'
import Chart from '../../assets/image/chart1.svg'
import Ellipse from '../../assets/image/ellipseChart.svg'

function Fund() {
  return (
    <div className={styles.fund}>
      <img src={Ellipse} alt="ellipse" className={styles.fund__ellipse} />
      <div className={styles.fund__title}>Fund distribution</div>
      <div className={styles.fund__wrapper}>
        <div className={styles.fund__container}>
          <img src={Chart} alt="chart" className={styles.fund__container__image} />
        </div>
        <div className={styles.fund__block}>
          <div className={styles.fund__block__top}>Total supply</div>
          <div className={styles.fund__block__center}>911 911 911</div>
          <div className={styles.fund__block__bottom}>$ZMBI</div>
        </div>
      </div>
    </div>
  )
}

export default Fund
