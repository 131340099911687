import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './Header.module.scss'
import TrumpImage1 from '../../assets/image/trump/1.png'
import TrumpImage2 from '../../assets/image/trump/2.png'
import TrumpImage3 from '../../assets/image/trump/3.png'
import TrumpImage4 from '../../assets/image/trump/4.png'
import TrumpImage5 from '../../assets/image/trump/5.png'
import TrumpImage6 from '../../assets/image/trump/6.png'
import TrumpImage7 from '../../assets/image/trump/7.png'
import HarrisImage1 from '../../assets/image/harris/1.png'
import HarrisImage2 from '../../assets/image/harris/2.png'
import HarrisImage3 from '../../assets/image/harris/3.png'
import HarrisImage4 from '../../assets/image/harris/4.png'
import HarrisImage5 from '../../assets/image/harris/5.png'
import HarrisImage6 from '../../assets/image/harris/6.png'
import HarrisImage7 from '../../assets/image/harris/7.png'
import Background from '../../assets/image/background1.jpg'
import Scroll from '../../assets/image/scroll.svg'
import FlashImage from '../../assets/image/flash.png'
import { ReactComponent as LightImage } from '../../assets/image/light.svg'
import Timer from '../Timer/Timer'

const trump = [
  TrumpImage1,
  TrumpImage2,
  TrumpImage3,
  TrumpImage4,
  TrumpImage5,
  TrumpImage6,
  TrumpImage7
]
const harris = [
  HarrisImage1,
  HarrisImage2,
  HarrisImage3,
  HarrisImage4,
  HarrisImage5,
  HarrisImage6,
  HarrisImage7
]

function Header() {
  const [increaseVotesHarris, setIncreaseVotesHarris] = useState(false)
  const [increaseVotesTrump, setIncreaseVotesTrump] = useState(false)
  const [increaseIndexHarris, setIncreaseIndexHarris] = useState(0)
  const [increaseIndexTrump, setIncreaseIndexTrump] = useState(0)
  const [isScrolled, setIsScrolled] = useState(false)
  const [initAnimation, setInitAnimation] = useState(false)
  const [currentIndexTrump, setCurrentIndexTrump] = useState(0)
  const [currentIndexHarris, setCurrentIndexHarris] = useState(0)
  const intervalRefTrump = useRef(null)
  const intervalRefHarris = useRef(null)
  const headerRef = useRef(null)
  const [windowSize, setWindowSize] = useState(window.innerWidth)

  const dispatch = useDispatch()
  const { trumpVotes, harrisVotes } = useSelector((state) => state?.votes)

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    trump.forEach((imageSrc) => {
      const img = new Image()
      img.src = imageSrc
    })
    harris.forEach((imageSrc) => {
      const img = new Image()
      img.src = imageSrc
    })
  })

  const startAnimation = (party) => {
    if (party === 'trump' && intervalRefTrump.current) {
      clearInterval(intervalRefTrump.current)
      intervalRefTrump.current = null
    }

    if (party === 'harris' && intervalRefHarris.current) {
      clearInterval(intervalRefHarris.current)
      intervalRefHarris.current = null
    }

    let index = 0
    if (party === 'trump') {
      setCurrentIndexTrump(index)
    } else {
      setCurrentIndexHarris(index)
    }

    if (party === 'trump') {
      intervalRefTrump.current = setInterval(() => {
        setCurrentIndexTrump((prevIndex) => {
          index = (prevIndex + 1) % trump.length
          return index
        })

        if (index === trump.length - 1) {
          clearInterval(intervalRefTrump.current)
          intervalRefTrump.current = null
          setTimeout(() => {
            setCurrentIndexTrump(0)
          }, 60)
        }
      }, 60)
    } else {
      intervalRefHarris.current = setInterval(() => {
        setCurrentIndexHarris((prevIndex) => {
          index = (prevIndex + 1) % harris.length
          return index
        })

        if (index === harris.length - 1) {
          clearInterval(intervalRefHarris.current)
          intervalRefHarris.current = null
          setTimeout(() => {
            setCurrentIndexHarris(0)
          }, 60)
        }
      }, 60)
    }
  }

  const sendHarrisVote = () => {
    if (!isScrolled) {
      dispatch({ type: 'votes/sendPartyVote', payload: 'DEMOCRATIC' })
      startAnimation('harris')
      setIncreaseIndexHarris(increaseIndexHarris + 1)
      setIncreaseVotesHarris(true)
    }
  }

  const sendTrumpVote = () => {
    if (!isScrolled) {
      dispatch({ type: 'votes/sendPartyVote', payload: 'REPUBLICAN' })
      startAnimation('trump')
      setIncreaseIndexTrump(increaseIndexTrump + 1)
      setIncreaseVotesTrump(true)
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      setInitAnimation(true)
      if (headerRef.current) {
        const scrollTop = headerRef.current.scrollTop
        if (scrollTop > 0) {
          setIsScrolled(true)
        } else {
          setIsScrolled(false)
        }
      }
    }

    const currentHeader = headerRef.current
    if (currentHeader) {
      currentHeader.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (currentHeader) {
        currentHeader.removeEventListener('scroll', handleScroll)
      }
    }
  }, [window.scrollY])

  useEffect(() => {
    if (increaseVotesHarris) {
      setTimeout(() => {
        setIncreaseVotesHarris(false)
        setIncreaseIndexHarris(0)
      }, 1000)
    }
  }, [increaseVotesHarris])

  useEffect(() => {
    if (increaseVotesTrump) {
      setTimeout(() => {
        setIncreaseVotesTrump(false)
        setIncreaseIndexTrump(0)
      }, 1000)
    }
  }, [increaseVotesTrump])

  useEffect(() => {
    dispatch({ type: 'votes/getVotes' })
  }, [])

  const harrisImage = useMemo(() => {
    return harris[currentIndexHarris]
  }, [harris, currentIndexHarris])

  const trumpImage = useMemo(() => {
    return trump[currentIndexTrump]
  }, [trump, currentIndexTrump])

  const containerClassNames = useMemo(() => {
    return initAnimation ? (isScrolled ? styles.header__container__scroll__animated : styles.header__container__scroll__notanimated) : ''
  }, [initAnimation, isScrolled])

  const scrollClassNames = useMemo(() => {
    return initAnimation ? (isScrolled ? styles.header__container__main__animated : styles.header__container__main__notanimated) : ''
  }, [initAnimation, isScrolled])

  const harrisClassNames = useMemo(() => {
    return initAnimation ? (isScrolled ? styles.header__container__harris__animated : styles.header__container__harris__notanimated) : ''
  }, [initAnimation, isScrolled])

  const trumpClassNames = useMemo(() => {
    return initAnimation ? (isScrolled ? styles.header__container__trump__animated : styles.header__container__trump__notanimated) : ''
  }, [initAnimation, isScrolled])

  const timerClassNames = useMemo(() => {
    return initAnimation ? (isScrolled ? styles.header__timer__animated : styles.header__timer__notanimated) : ''
  }, [initAnimation, isScrolled])

  return (
    <div
      ref={headerRef}
      className={`${styles.header}`}
      style={{ height: windowSize > 1100 ? '1000px' : '100vh', overflowY: 'scroll' }}
    >
      <div className={styles.header__container}>
        <div className={styles.header__container__gradient}></div>
        <div
          className={`${styles.header__container__scroll} ${containerClassNames}`}
        >
          <img src={Scroll} alt="scroll" />
          <div className={styles.header__container__scroll__text}>Scroll Down</div>
        </div>
        <div
          className={`${styles.header__container__main} ${scrollClassNames}`}
        >
          <p className={styles.header__container__main__text}>
            TAP for <br />
            the Doomsday
            <br />
            you want!
          </p>
        </div>
        <div
          className={`${styles.header__container__harris} ${harrisClassNames}`}
        >
          <img
            onClick={sendHarrisVote}
            src={harrisImage}
            className={styles.header__container__harris__image}
          />
          <div className={styles.header__container__harris__vote}>
            <div className={styles.header__container__harris__title}>HARRIS</div>
            <div className={styles.header__container__harris__count}>{harrisVotes}</div>
            <div className={styles.header__container__harris__vote__increase}>
              {increaseVotesHarris && `+${increaseIndexHarris}`}
            </div>
          </div>
          <button onClick={sendHarrisVote} className={styles.header__container__harris__button}>
            VOTE
          </button>
        </div>
        <div
          className={`${styles.header__container__trump} ${trumpClassNames}`}
        >
          <img
            onClick={sendTrumpVote}
            src={trumpImage}
            className={styles.header__container__trump__image}
          />
          <div className={styles.header__container__trump__vote}>
            <div className={styles.header__container__trump__title}>TRUMP</div>
            <div className={styles.header__container__trump__count}>{trumpVotes}</div>
            <div className={styles.header__container__trump__vote__increase}>
              {increaseVotesTrump && `+${increaseIndexTrump}`}
            </div>
          </div>
          <button onClick={sendTrumpVote} className={styles.header__container__trump__button}>
            VOTE
          </button>
        </div>
      </div>
      {windowSize > 1100 && (
        <div
          className={`${styles.header__timer} ${timerClassNames}`}
        >
          <LightImage className={styles.header__light} />
          <img className={styles.header__flash} src={FlashImage} />
          <Timer />
        </div>
      )}
    </div>
  )
}

export default Header
