import Header from './components/Header/Header'
import Fund from './components/Fund/Fund'
import Rocket from './components/Rocket/Rocket'
import Journey from './components/Journey/Journey'
import Footer from './components/Footer/Footer'
import Navigation from './components/Navigation/Navigation'
import Slider from './components/Slider/Slider'
import TimerWrapper from './components/TimerWrapper/TimerWrapper'
import './App.scss'
import React, { useState, useEffect } from 'react'
import Fullpage from '@fullpage/react-fullpage'

const sections = [
  { id: 'header', content: <Header /> },
  { id: 'timer', content: <TimerWrapper /> },
  { id: 'rocket', content: <Rocket /> },
  { id: 'fund', content: <Fund /> },
  { id: 'slider', content: <Slider /> },
  { id: 'journey', content: <Journey /> },
  { id: 'footer', content: <Footer /> }
]

function App() {
  const [windowSize, setWindowSize] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const showTimer = windowSize < 1100

  const filteredSections = sections.filter((section) => {
    if (section.id === 'timer') {
      return showTimer
    }
    return true
  })
  return (
    <>
      <Navigation />
      <Fullpage
        scrollingSpeed={1000}
        scrollOverflow={true}
        render={({ state, fullpageApi }) => (
          <div>
            {filteredSections.map((section) => (
              <div
                key={section.id}
                id={section.id}
                className="section"
                style={{ height: section.id === 'header' ? '1000px' : '100vh' }}
              >
                {section.content}
              </div>
            ))}
          </div>
        )}
      />
    </>
  )
}

export default App
