import React, { useState, useRef, useEffect } from 'react'
import styles from './Slider.module.scss'
import Arrow from '../../assets/image/arrow.svg'
import Image1 from '../../assets/image/slider/1.png'
import Image2 from '../../assets/image/slider/2.png'
import Image3 from '../../assets/image/slider/3.png'
import Image4 from '../../assets/image/slider/4.png'
import Image5 from '../../assets/image/slider/5.png'

function Slider() {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const sliderRef = useRef(null)

  const cardWidth = windowWidth > 600 ? 464 : 340

  const visibleCards = Math.floor(windowWidth / cardWidth)

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const cards = [
    {
      image: Image1,
      description: 'Beta testing - done',
      date: 'Apr 24'
    },
    {
      image: Image2,
      description: '300k players',
      date: 'Aug 24'
    },
    {
      image: Image3,
      description: '$ZMBI rocketing',
      date: 'Sep 24'
    },
    {
      image: Image4,
      description: '1,000,000 players',
      date: 'Dec 24'
    },
    {
      image: Image5,
      description: 'Conquest of our Galaxy',
      date: '2025'
    }
  ]

  const handleNext = () => {
    if (currentIndex < cards.length - visibleCards) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }

  return (
    <div className={styles.slider}>
      <div className={styles.slider__header}>OUR JOURNEY TO ETERNITY</div>
      <div className={styles.slider__arrows}>
        <button disabled={currentIndex === 0} onClick={handlePrev}>
          <img className={styles.slider__arrows__prev} src={Arrow} alt="arrow-disable" />
        </button>
        <button disabled={currentIndex >= cards.length - visibleCards} onClick={handleNext}>
          <img src={Arrow} alt="arrow" />
        </button>
      </div>
      <div
        className={styles.slider__slider}
        ref={sliderRef}
        style={{ transform: `translateX(-${currentIndex * cardWidth}px)` }}
      >
        {cards.map((item, index) => (
          <div key={index} className={styles.slider__card}>
            <img className={styles.slider__card__image} src={item.image} alt="images" />
            <div className={styles.slider__card__bottom}>
              <div className={styles.slider__card__bottom__description}>{item.description}</div>
              <div className={styles.slider__card__bottom__date}>{item.date}</div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.slider__pagination}>
        <ul className={styles.slider__pagination__list}>
          {cards.map((_, index) => (
            <li className={`${styles.slider__pagination__item} ${index === currentIndex ? styles.slider__pagination__item__active : ''}`}></li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Slider
