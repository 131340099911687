import React from 'react'
import Timer from '../Timer/Timer'
import styles from './TimerWrapper.module.scss'

function TimerWrapper() {
  return (
    <div className={styles.timer}>
      <Timer />
    </div>
  )
}

export default TimerWrapper
